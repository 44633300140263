// extracted by mini-css-extract-plugin
export var connectWithCommunityBlock = "styles-module--connectWithCommunityBlock--3mubh";
export var connectWithCommunityBlockInnerContainer = "styles-module--connectWithCommunityBlockInnerContainer--3uThG";
export var polygon = "styles-module--polygon--z6lyP";
export var dots = "styles-module--dots--3t2Zv";
export var textContainer = "styles-module--textContainer--32n9l";
export var heading = "styles-module--heading--2RuT_";
export var body = "styles-module--body--arM_v";
export var videoOuterContainer = "styles-module--videoOuterContainer--3_CO-";
export var videoInnerContainer = "styles-module--videoInnerContainer--113qr";
export var tabletHeading = "styles-module--tabletHeading--2MLXE";
export var video = "styles-module--video--bme9K";