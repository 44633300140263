import React from 'react'
import clsx from "clsx"

import { styles } from './styles'
import { PlusIcon, MinusIcon } from '../../Icons'
import { Accordion } from '../../Accordion'
import { parseMarkdown } from '../../../utils'
import { globalStyles } from '../../../styles'

export interface IFAQBlock {
  heading: string
  questions: {
    question: string
    answer: string
  }[]
}

const FAQBlock: React.FC<IFAQBlock> = ({ heading, questions }) => {
  return (
    <section className={styles.faqBlock} id="faq">
      <h2 className={clsx(globalStyles.heading2, styles.heading)}>{heading}</h2>
      <div className={styles.accordionContainer}>
        <Accordion 
          accordionContent={questions.map((question) => { 
            return { 
              heading: question.question, 
              body: <p className={clsx(globalStyles.body1, styles.accordionItemBody)} dangerouslySetInnerHTML={{ __html: parseMarkdown(question.answer) }} />
           }})}
          accordionClassName={styles.accordion}
          accordionItemClassName={styles.accordionItem}
          accordionItemHeadingClassName={globalStyles.heading3}
          accordionItemButtonClassName={styles.accordionButton}
          accordionItemPanelClassName={styles.accordionBodyContainer}
          expandedButton={<MinusIcon />}
          closedButton={<PlusIcon />}
          allowMultipleExpanded 
          allowZeroExpanded 
        />
      </div>
    </section>
  )
}

export default FAQBlock