import  React  from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { parseMarkdown } from '../../../utils'
import { globalStyles } from '../../../styles'

export interface IFooterBlock {
  description: string
  platform: {
    heading: string
    links: {
      url: string
      title: string
    }[]
  }
  learn: {
    heading: string
    links: {
      url: string
      title: string
    }[]
  }
  help: {
    heading: string
    salesMail: string
    contactMail: string
  }
  termsAndConditionsUrl: string
  privacyPolicyUrl: string
  instagramUrl: string
  facebookUrl: string
}

const FooterBlock: React.FC<IFooterBlock> = (props) => { 
  const { 
    description, 
    platform, 
    learn, 
    help, 
    termsAndConditionsUrl, 
    privacyPolicyUrl, 
    instagramUrl, 
    facebookUrl 
  } = props

  return (
    <section className={styles.footerBlock}>
      <div className={styles.upperHalfContainer}>
        <div className={styles.logoContainer}>
          <a href="#">
            <img className={styles.logo} src="static/images/class-kid-logo.svg" alt="ClassKid"/>
          </a>
          <p className={globalStyles.body1} dangerouslySetInnerHTML={{ __html: parseMarkdown(description) }} />
        </div>
        <div className={styles.footerNav}>
          <div>
            <p className={clsx(globalStyles.heading3, styles.linkHeading)}>{platform.heading}</p>
            {platform.links.map((link) => {
              return (
                <a className={clsx(globalStyles.body1, styles.footerLink)} href={link.url}>{link.title}</a>
              )
            })}
          </div>
          <div>
            <p className={clsx(globalStyles.heading3, styles.linkHeading)}>{learn.heading}</p>
            {learn.links.map((link) => {
              return (
                <a className={clsx(globalStyles.body1, styles.footerLink)} href={link.url}>{link.title}</a>
              )
            })}
          </div>
          <div>
            <p className={clsx(globalStyles.heading3, styles.help)}>{help.heading}</p>
            <div className={styles.contactContainer}>
              <p className={clsx(globalStyles.body2, styles.contactLabel)}>Contact Sales</p>
              <p className={clsx(globalStyles.body2, styles.contactMail)}>{help.salesMail}</p>
            </div>
            <div className={styles.contactContainer}>
              <p className={clsx(globalStyles.body2, styles.contactLabel)}>Contact Support</p>
              <p className={clsx(globalStyles.body2, styles.contactMail)}>{help.contactMail}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.lowerHalfContainer}>
        <div className={styles.socialIconsContainer}>
          <a href={instagramUrl}>
            <img src="static/images/icons-insta.png" alt="instagram"/>
          </a>
          <a href={facebookUrl}>
            <img src="static/images/icons-fb.png" alt="facebook"/>
          </a>
        </div>
        <div className={styles.termsAndPrivacyContainer}>
          <div>
            <span className={clsx(globalStyles.body2, styles.termsAndPrivacy)}><a href={termsAndConditionsUrl}>Terms & conditions</a> | <a href={privacyPolicyUrl}>Privacy policy</a></span>
          </div>
          <div>
            <span className={globalStyles.body2}>© 2021 to Current • ClassKid, LLC</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FooterBlock