import React from 'react'
import { graphql } from "gatsby";

import { NavBar, IndexPageTemplate } from '../components'

interface IIndexPage {
  data: {
    homePageData: {
      frontmatter: {
        navigationLinks: {
          url: string
          title: string
        }[]
        joinButton: {
          text: string
        }
        signInButton: {
          url: string
          text: string
        }
        bookDemoButton: {
          url: string
          text: string
        }
        headerBlock: {
          heading: {
            uncoloredText: string
            coloredText: string
          }
          description: string
          subheading: string
          id: string
        }
        grabACopyBlock: {
          heading: string
          subheading: string
          image: string
          downloadButtonUrl: string
          downloadButtonText: string
        }
        withoutClassKidBlock: {
          heading1: string
          heading2: string
          description: string
          testimonials: {
            name: string
            position: string
            text: string
            avatar: string
          }[]
        }
        marketYourClassesBlock: {
          marketYourClassesBlockElements: {
            heading: string
            description: string
            frontImage: string
            backImage: string
          }[]
        }
        attractStudentsBlock: {
          image: string
          sliderContent: {
            heading: string
            description: string
          }[]
        }
        connectWithCommunityBlock: {
          heading: string
          body: string
          id: string
        }
        featuresBlock: {
          heading: string
          features: {
            title: string
            body: string
          }[]
        }
        growWithClassKidBlock: {
          heading: string
          features: {
            title: string
            body: string
            image: string
          }[]
        }
        faqBlock: {
          heading: string
          questions: {
            question: string
            answer: string
          }[]
        }
        personalizedDemoBlock: {
          heading: string
          description: string
          mailToText: string
          email: string
          id: string
        }
        becomeAPartOfSomethingBiggerBlock: {
          heading: {
            topUncoloredText: string
            coloredText: string
            bottomUncoloredText: string 
          }
          description: string
          buttonImage: string
          buttonUrl: string
          image: string
        }
        tryClassKidForFreeBlock: {
          description: string
        }
        footerBlock: {
          description: string
          platform: {
            heading: string
          }
          learn: {
            heading: string
            links: {
              url: string
              title: string
            }[]
          }
          help: {
            heading: string
            salesMail: string
            contactMail: string
          }
          termsAndConditionsUrl: string
          privacyPolicyUrl: string
          instagramUrl: string
          facebookUrl: string
        }
      }
    }
  }
}

const IndexPage: React.FC<IIndexPage> = ({ data }) => {
  const queryData = data.homePageData.frontmatter

  return (
    <div>
      <NavBar 
        joinButton={queryData.joinButton}
        signInButton={queryData.signInButton}
        navigationLinks={queryData.navigationLinks} 
        termsAndConditionsUrl={queryData.footerBlock.termsAndConditionsUrl} 
        privacyPolicyUrl={queryData.footerBlock.privacyPolicyUrl} 
      />
      <IndexPageTemplate
        bookDemoButton={queryData.bookDemoButton}
        joinButton={queryData.joinButton}
        headerBlock={{ ...queryData.headerBlock, bookDemoButton: queryData.bookDemoButton, joinButton: queryData.joinButton }}
        grabACopyBlock={queryData.grabACopyBlock} 
        withoutClassKidBlock={queryData.withoutClassKidBlock}
        marketYourClassesBlock={{ ...queryData.marketYourClassesBlock, bookDemoButton: queryData.bookDemoButton, joinButton: queryData.joinButton }}
        attractStudentsBlock={queryData.attractStudentsBlock}
        connectWithCommunityBlock={queryData.connectWithCommunityBlock}
        featuresBlock={queryData.featuresBlock}
        growWithClassKidBlock={{ ...queryData.growWithClassKidBlock, bookDemoButton: queryData.bookDemoButton, joinButton: queryData.joinButton }}
        faqBlock={queryData.faqBlock}
        personalizedDemoBlock={{ ...queryData.personalizedDemoBlock, bookDemoButton: queryData.bookDemoButton, joinButton: queryData.joinButton }}
        becomeAPartOfSomethingBiggerBlock={queryData.becomeAPartOfSomethingBiggerBlock}
        tryClassKidForFreeBlock={{ ...queryData.tryClassKidForFreeBlock, bookDemoButton: queryData.bookDemoButton, joinButton: queryData.joinButton }}
        footerBlock={{...queryData.footerBlock, platform: { ...queryData.footerBlock.platform, links: queryData.navigationLinks }}}
      />
    </div>
  )
}

export const pageQuery = graphql`
 query IndexPageQuery {
  homePageData: markdownRemark {
    frontmatter {
      navigationLinks {
        url
        title
      }
      joinButton {
        text
      }
      signInButton {
        url
        text
      }
      bookDemoButton {
        url
        text
      }
      headerBlock {
        heading {
          uncoloredText
          coloredText
        }
        description
        subheading
        id
      }
      grabACopyBlock {
        heading
        subheading
        image
        downloadButtonUrl
        downloadButtonText
      }
      withoutClassKidBlock {
        heading1
        heading2
        description
        testimonials {
          name
          position
          avatar
          text
        }
      }
      marketYourClassesBlock {
        marketYourClassesBlockElements {
          heading
          description
          frontImage
          backImage
        }
      }
      attractStudentsBlock {
        image
        sliderContent {
          heading
          description
        }
      }
      connectWithCommunityBlock {
        heading
        body
        id
      }
      featuresBlock {
        heading
        features {
          title
          body
        }
      }
      growWithClassKidBlock {
        heading
        features {
          title
          body
          image
        }
      }
      faqBlock {
        heading
        questions {
          question
          answer
        }
      }
      personalizedDemoBlock {
        heading
        description
        mailToText
        email
        id
      }
      becomeAPartOfSomethingBiggerBlock {
        description
        buttonImage
        buttonUrl
        image
        heading {
          topUncoloredText
          coloredText
          bottomUncoloredText 
        }
      }
      tryClassKidForFreeBlock {
        description
      }
      footerBlock {
        description
        platform {
          heading
        }
        learn {
          heading
          links {
            url
            title
          }
        }
        help {
          heading
          salesMail
          contactMail
        }
        termsAndConditionsUrl
        privacyPolicyUrl
        instagramUrl
        facebookUrl
      }
    }
  }
 }
`;

export default IndexPage
