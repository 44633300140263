import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

interface IAccordionComponent {
  accordionContent: {
    heading: string
    body: JSX.Element
  }[]
  expandedButton?: JSX.Element
  closedButton?: JSX.Element
  allowMultipleExpanded?: boolean
  allowZeroExpanded?: boolean
  accordionClassName?: string
  accordionItemClassName?: string
  ariaLevel?: number
  accordionItemHeadingClassName?: string
  accordionItemButtonClassName?: string
  accordionItemPanelClassName?: string
}

const AccordionComponent: React.FC<IAccordionComponent>  = ({ 
  accordionContent, 
  expandedButton,
  closedButton,
  allowMultipleExpanded = true, 
  allowZeroExpanded = true, 
  accordionClassName,
  accordionItemClassName,
  ariaLevel,
  accordionItemHeadingClassName,
  accordionItemButtonClassName,
  accordionItemPanelClassName
}) => {

  const renderAccordionContent = accordionContent.map((accordionPanel) => {
    return (
      <>
        <AccordionItem className={accordionItemClassName}>
          <AccordionItemState>
            {({ expanded }) => (
            <AccordionItemHeading className={accordionItemHeadingClassName} aria-level={ariaLevel}>
              <AccordionItemButton className={accordionItemButtonClassName}>
                {accordionPanel.heading}
                <div>
                  {expanded ? expandedButton : closedButton}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            )}
          </AccordionItemState>
          <AccordionItemPanel className={accordionItemPanelClassName}>
           {accordionPanel.body}
          </AccordionItemPanel>
        </AccordionItem>
      </>
    )
  })

  return (
    <Accordion allowMultipleExpanded={allowMultipleExpanded} allowZeroExpanded={allowZeroExpanded} className={accordionClassName}>
      {renderAccordionContent}
    </Accordion>
  )
}

export default AccordionComponent