import React from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { ThinArrowRightIcon } from '../../Icons'
import { CarouselSlider } from '../../CarouselSlider'
import { parseMarkdown } from '../../../utils'
import { globalStyles } from '../../../styles'

export interface IAttractStudentsBlock {
  sliderContent: {
    heading: string
    description: string
  }[]
  image: string
}

const AttractStudentsBlock: React.FC<IAttractStudentsBlock> = ({ image, sliderContent }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const prevIsDisabled = !currentSlide
  const nextIsDisabled = currentSlide === sliderContent.length - 1

  const onSlideChange = (index: number) => setCurrentSlide(index)
  
  const onClickNext = () => {
    if (!nextIsDisabled) {
      setCurrentSlide(currentSlide + 1)
    }
  }
  const onClickPrev = () => {
    if (!prevIsDisabled) {
      setCurrentSlide(currentSlide - 1)
    } 
  }

  const headerSlides = sliderContent.map((slide) => {
    return (
      <div className={styles.sliderContent}>
        <p className={clsx(globalStyles.heading2, styles.heading)}>{slide.heading}</p>
      </div>
    )
  })

  const descriptionSlides = sliderContent.map((slide) => {
    return (
      <div className={styles.sliderContent}>
        <p className={globalStyles.body1} dangerouslySetInnerHTML={{ __html: parseMarkdown(slide.description) }} />
      </div>
    )
  })

  return (
    <section className={styles.attractStudentsOuterContainer} id="benefits">
      <div className={styles.attractStudentsContainer}>
        <div className={styles.sliderContainer}>
          <CarouselSlider carouselCards={headerSlides} selectedItem={currentSlide} onChange={onSlideChange} />
          <div className={styles.sliderImageContainer}>
            <img className={styles.sliderImage} src={image} />
            <img src="static/images/polygon.png" className={styles.mobilePolygon} />
          </div>
          <CarouselSlider carouselCards={descriptionSlides} selectedItem={currentSlide} onChange={onSlideChange} />
          <div className={styles.sliderButtons}>
            <button onClick={onClickPrev} disabled={prevIsDisabled} className={prevIsDisabled && styles.disabled}>
              <ThinArrowRightIcon color={prevIsDisabled ? "#2c374b" : "#d63dd9"} className={clsx(styles.prevIcon, prevIsDisabled && styles.iconDisabled)} />
            </button>
            <button 
              onClick={onClickNext} 
              disabled={nextIsDisabled} 
              className={nextIsDisabled ? styles.disabled : undefined} 
            >
              <ThinArrowRightIcon 
                color={nextIsDisabled ? "#2c374b" : "#d63dd9"} 
                className={nextIsDisabled && styles.iconDisabled} />
            </button>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img src="static/images/polygon.png" className={styles.polygon} />
          <img className={styles.image} src={image} />  
        </div>
      </div>
    </section>
  )
}

export default AttractStudentsBlock