import React from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { DoneIcon } from '.././../Icons'
import { globalStyles } from '../.././../styles'
import { parseMarkdown } from '../../../utils'

export interface IFeaturesBlock {
  heading: string
  features: {
    title: string
    body: string
  }[]
}

const FeaturesBlock: React.FC<IFeaturesBlock> = ({ heading, features }) => {
  return (
    <section className={styles.featuresBlock}>
      <h2 className={clsx(globalStyles.heading2, styles.heading)} id="features">{heading}</h2>
      <ul className={styles.featuresContainer}>
        {features.map((feature, index) => {
          return (
            <li className={styles.featureContent} key={`feature-${index}`}>
              <div className={styles.featureTitleContainer}>
                <DoneIcon color="white" backgroundColor="#e49b1d" className={styles.checkIcon} />
                <p className={clsx(globalStyles.heading3, styles.featureTitle)}>{feature.title}</p>
              </div>
              <p className={globalStyles.body1} dangerouslySetInnerHTML={{__html: parseMarkdown(feature.body)}} />
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default FeaturesBlock
