import React from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { globalStyles } from '../../styles'

export interface ITestimonialCard {
  text: string
  name: string
  position: string
  avatar:  string
}

const TestimonialCard: React.FC<ITestimonialCard> = ({ name, position, text, avatar }) => {
  return (
    <div className={styles.cardContent}>
      <div>
        <p className={styles.quote}>"</p>
        <p className={clsx(globalStyles.body1, styles.testimonialText)}>{text}</p>
      </div>
      <div className={styles.testimonialUserContainer}>
        <div>
          <img className={styles.avatar} src={avatar}/>
        </div>
        <div>
          <p className={clsx(globalStyles.body1, styles.userName)}>{name}</p>
          <p className={globalStyles.body1}>{position}</p>
        </div>
      </div>
    </div>
  )
}

export default TestimonialCard