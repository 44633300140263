// extracted by mini-css-extract-plugin
export var marketYourClassesBlockContainer = "styles-module--marketYourClassesBlockContainer--2l4vS";
export var container = "styles-module--container--2Xamc";
export var manageClassesContainer = "styles-module--manageClassesContainer--2G_TF";
export var textContainer = "styles-module--textContainer--3fjb-";
export var textContainerStyles = "styles-module--textContainerStyles--3Dhpr";
export var heading = "styles-module--heading--1SDQ1";
export var headingMobile = "styles-module--headingMobile--Z8wGE";
export var description = "styles-module--description--1dJT7";
export var buttonsContainer = "styles-module--buttonsContainer--1nOIR";
export var calendarIcon = "styles-module--calendarIcon--3N8Kl";
export var arrowIcon = "styles-module--arrowIcon--1g_bs";
export var backgroundImage = "styles-module--backgroundImage--20Uty";
export var frontImage = "styles-module--frontImage--1s7V7";
export var imageContainer = "styles-module--imageContainer--3-ksv";
export var imageContainerStyles = "styles-module--imageContainerStyles--3HjuE";
export var frontImageStyles = "styles-module--frontImageStyles--1NyvI";
export var button = "styles-module--button--3zgVH";