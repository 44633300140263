// extracted by mini-css-extract-plugin
export var tryClassKidForFreeBlock = "styles-module--tryClassKidForFreeBlock--kiemq";
export var heading = "styles-module--heading--GCBzp";
export var dot = "styles-module--dot--22YRa";
export var description = "styles-module--description--aFkpM";
export var buttonContainer = "styles-module--buttonContainer--3TWNu";
export var button = "styles-module--button--1Bj0S";
export var bookDemoButton = "styles-module--bookDemoButton--3T56M";
export var buttonText = "styles-module--buttonText--2zWI_";
export var calendarIcon = "styles-module--calendarIcon--1Lmqn";
export var arrowIcon = "styles-module--arrowIcon--2Zxis";
export var polygonRight = "styles-module--polygonRight--XK240";
export var polygonLeft = "styles-module--polygonLeft--3xl9R";
export var dots = "styles-module--dots--2Hr_i";