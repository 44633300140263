import React from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { Button } from '../../Buttons'
import { CalendarIcon, ArrowRight } from '../../Icons'
import { parseMarkdown } from '../../../utils'
import { globalStyles } from '../../../styles'

export interface IPersonalizedDemoBlock {
  heading: string
  description: string
  mailToText: string
  email: string
  id: string
  bookDemoButton: {
    url: string 
    text: string
  }
  joinButton: {
    text: string
  }
}

const PersonalizedDemoBLock: React.FC<IPersonalizedDemoBlock> = ({ heading, description, mailToText, email, id, bookDemoButton, joinButton }) => {
  return (
    <section className={styles.personalizedDemoBLock}>
      <div className={styles.textOuterContainer}>
        <div className={styles.textInnerContainer}>
          <h2 className={clsx(globalStyles.heading2, styles.heading)}>{heading}</h2>
          <p className={clsx(globalStyles.body1, styles.description)} dangerouslySetInnerHTML={{ __html: parseMarkdown(description) }} />
          <div className={styles.buttonContainer}>
            <Button
              className={styles.bookDemoButton}
              text={bookDemoButton.text}
              url={bookDemoButton.url}
              onClick={() => 0}
              startAdornment={<CalendarIcon className={styles.icon} color="#d63dd9" />}
              outline
            />
            <Button 
              className={clsx(styles.joinButton, 'vrlps-trigger')} 
              text={joinButton.text} 
              url="#"
              endAdornment={<ArrowRight color="white" className={styles.arrowIcon} />} 
            />
          </div>
          <div className={styles.sendMailContainer}>
            <span className={globalStyles.body2}>{`${mailToText} `}</span>
            <span id={styles.email} className={globalStyles.body2}>{email}</span>
          </div>
        </div>
      </div>
      <div className={styles.videoOuterContainer}>
        <p className={clsx(globalStyles.heading2, styles.mobileHeading)}>{heading}</p> 
        <div className={styles.videoContainer}> 
          <iframe 
            src={`//fast.wistia.net/embed/iframe/${id}`}
            allowTransparency
            frameBorder={0}
            scrolling="no" 
            className={styles.video}
            allowFullScreen 
          />
        </div>
      </div>
    </section>
  )
}

export default PersonalizedDemoBLock