import React from 'react'

import { TestimonialCard, ITestimonialCard } from '../TestimonialCard'
import { CarouselSlider } from '../CarouselSlider'

interface ITestimonialSlider {
  testimonials: ITestimonialCard[]
}

const TestimonialSlider: React.FC<ITestimonialSlider> = ({ testimonials }) => {

  const testimonialCards = testimonials.map((testimonial) => {
    return (
        <TestimonialCard 
          name={testimonial.name} 
          position={testimonial.position} 
          avatar={testimonial.avatar} 
          text={testimonial.text} 
        />
    )
  })

  return (
    <CarouselSlider carouselCards={testimonialCards} center={{ centerMode: true, centerSlidePercentage: 70 }} />
  )
}

export default TestimonialSlider