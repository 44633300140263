// extracted by mini-css-extract-plugin
export var withoutClassKidBlockContainer = "styles-module--withoutClassKidBlockContainer--3EC4_";
export var withoutClassKidBlockInnerContainer = "styles-module--withoutClassKidBlockInnerContainer--2MR7D";
export var withoutClassKidTextContainer = "styles-module--withoutClassKidTextContainer--cGHuQ";
export var heading1 = "styles-module--heading1--11yGW";
export var heading2 = "styles-module--heading2--2-ASu";
export var description = "styles-module--description--22wz8";
export var testimonialCardList = "styles-module--testimonialCardList--1edJg";
export var testimonialMobileSlider = "styles-module--testimonialMobileSlider--zt3-M";
export var logosAnimationContainer = "styles-module--logosAnimationContainer--2r7sN";
export var logosAnimationClassKid = "styles-module--logosAnimationClassKid--2Eomk";
export var logosAnimationBlock = "styles-module--logosAnimationBlock--d9P9W";
export var img1 = "styles-module--img1--14HfP";
export var img3 = "styles-module--img3--6l8df";
export var img4 = "styles-module--img4--6hy2W";
export var img5 = "styles-module--img5--2KWPi";
export var img8 = "styles-module--img8--2-lhi";
export var img11 = "styles-module--img11--iP5Wf";
export var img12 = "styles-module--img12--3ur7_";
export var img13 = "styles-module--img13--eaONw";
export var img14 = "styles-module--img14--28BEg";
export var img15 = "styles-module--img15--tstCH";
export var img16 = "styles-module--img16--31MDd";
export var img9 = "styles-module--img9--22I68";