import React from 'react'

interface IIcon {
  onClick?: () => void
  className?: string
  color?: string
  backgroundColor?: string
}

export const MenuIcon: React.FC<IIcon> = ({ color, className, onClick }) => {
  return (
    <svg 
      className={className || ''} 
      height="20" 
      viewBox="0 -53 384 384" 
      width="20" 
      xmlns="http://www.w3.org/2000/svg" 
      onClick={() => onClick && onClick()}
    >
      <path fill={color || 'black'} d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
      <path fill={color || 'black'} d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
      <path fill={color || 'black'} d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
    </svg>
  )
}

export const CloseIcon: React.FC<IIcon> = ({ color, className, onClick }) => {
  return (
    <svg 
      className={className || ''} 
      height="20" 
      viewBox="0 0 329.26933 329" 
      width="20" 
      xmlns="http://www.w3.org/2000/svg" 
      onClick={() => onClick && onClick()}
    >
      <path fill={color || 'black'} d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
    </svg>
  )
}
export const CalendarIcon: React.FC<IIcon> = ({ color, className, onClick }) => {
  return (
    <svg 
      className={className || ''} 
      enableBackground="new 0 0 18 18" 
      height="18" 
      viewBox="0 0 512 512" 
      width="18" 
      xmlns="http://www.w3.org/2000/svg" 
      onClick={() => onClick && onClick()}
    >
      <path fill={color || 'black'} d="m446 40h-46v-24c0-8.836-7.163-16-16-16s-16 7.164-16 16v24h-224v-24c0-8.836-7.163-16-16-16s-16 7.164-16 16v24h-46c-36.393 0-66 29.607-66 66v340c0 36.393 29.607 66 66 66h380c36.393 0 66-29.607 66-66v-340c0-36.393-29.607-66-66-66zm34 406c0 18.778-15.222 34-34 34h-380c-18.778 0-34-15.222-34-34v-265c0-2.761 2.239-5 5-5h438c2.761 0 5 2.239 5 5z"/>
    </svg>
  )
}

export const ArrowRight: React.FC<IIcon> = ({ color, className, onClick }) => {
  return (
    <svg 
      className={className || ''} 
      enableBackground="new 0 0 14 12" 
      height="12" 
      viewBox="0 0 512 512" 
      width="14" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick && onClick()}
    >
      <path 
        fill={color || 'black'} 
        d="M466.201,237.781L231.534,3.115C229.55,1.131,226.841,0,224.003,0h-192c-4.309,0-8.213,2.603-9.856,6.592
        s-0.725,8.555,2.304,11.627l227.136,227.115L24.451,472.448c-3.051,3.051-3.968,7.637-2.304,11.627
        c1.664,3.989,5.547,6.592,9.856,6.592h192c2.837,0,5.547-1.131,7.552-3.115l234.667-234.667
        C470.382,248.704,470.382,241.963,466.201,237.781z"
      />
    </svg>
  )
}

export const DownloadIcon: React.FC<IIcon> = ({ color, className, onClick }) => {
  return (
    <svg 
      className={className || ''} 
      enableBackground="new 0 0 18 18" 
      height="18" 
      viewBox="0 0 512 512" 
      width="18" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick && onClick()}
    >
      <path
        fill={color || 'black'} 
        d="m409.785156 278.5-153.785156 153.785156-153.785156-153.785156 28.285156-28.285156 105.5 105.5v-355.714844h40v355.714844l105.5-105.5zm102.214844 193.5h-512v40h512zm0 0"
      />
    </svg>
  )
}

export const ThinArrowRightIcon: React.FC<IIcon> = ({ color, className, onClick }) => {
  return (
    <svg 
      className={className || ''} 
      enableBackground="new 0 0 18 18" 
      height="15" 
      viewBox="0 0 512 512" 
      width="15" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick && onClick()}
    >
      <path
        fill={color || 'black'} 
        d="M0,244.8c0,9.5,7.7,17.2,17.2,17.2h414.2L322.9,370.4c-3.3,3.3-5,7.7-5,12.1s1.7,8.8,5,12.1c6.7,6.7,17.6,6.7,24.3,0
				l137.6-137.7c6.4-6.4,6.4-17.8,0-24.3L347.1,95c-6.7-6.7-17.6-6.7-24.3,0c-6.7,6.7-6.7,17.6,0,24.3l108.4,108.4H17.1
				C7.6,227.6,0,235.3,0,244.8z"
      />
    </svg>
  )
}

export const DoneIcon: React.FC<IIcon> = ({ color, backgroundColor, className, onClick }) => {
  return (
    <svg
      className={className || ''} 
      enableBackground="new 0 0 18 18" 
      height="20" 
      viewBox="0 0 512 512" 
      width="20" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick && onClick()}
    >
      <ellipse fill={backgroundColor} cx="256" cy="256" rx="256" ry="255.832"/>
      <polygon fill={color} points="235.472,392.08 114.432,297.784 148.848,253.616 223.176,311.52 345.848,134.504 391.88,166.392 "/>
    </svg>
  )
}

export const PlusIcon: React.FC<IIcon> = ({ color, className, onClick }) => {
  return (
    <svg
      className={className || ''} 
      enableBackground="new 0 0 18 18" 
      height="20" 
      viewBox="0 0 512 512" 
      width="20" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick && onClick()}
    >
      <path 
        fill={color || 'black'}  
        d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
			  v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z" 
      />
    </svg>
  )
}

export const MinusIcon: React.FC<IIcon> = ({ color, className, onClick }) => {
  return (
    <svg
      className={className || ''} 
      enableBackground="new 0 0 18 18" 
      height="18" 
      viewBox="0 0 512 512" 
      width="18" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick && onClick()}
    >
      <path 
        fill={color || 'black'}  
        d="M392.533,187.733H17.067C7.641,187.733,0,195.374,0,204.8s7.641,17.067,17.067,17.067h375.467
			  c9.426,0,17.067-7.641,17.067-17.067S401.959,187.733,392.533,187.733z"
      />
    </svg>
  )
}