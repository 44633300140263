// extracted by mini-css-extract-plugin
export var attractStudentsOuterContainer = "styles-module--attractStudentsOuterContainer--2MHN8";
export var attractStudentsContainer = "styles-module--attractStudentsContainer--1VIJr";
export var sliderContainer = "styles-module--sliderContainer--3HFA6";
export var sliderContent = "styles-module--sliderContent--LEuOR";
export var sliderImage = "styles-module--sliderImage--1bzg3";
export var heading = "styles-module--heading--2aVIV";
export var sliderButtons = "styles-module--sliderButtons--AVE5_";
export var prevIcon = "styles-module--prevIcon--370Rb";
export var disabled = "styles-module--disabled--GRKZH";
export var iconDisabled = "styles-module--iconDisabled--3C5sb";
export var imageContainer = "styles-module--imageContainer--1MWTZ";
export var image = "styles-module--image--uMSWF";
export var polygon = "styles-module--polygon--BGwEO";
export var mobilePolygon = "styles-module--mobilePolygon--3bzsM";
export var sliderImageContainer = "styles-module--sliderImageContainer--1DwEr";