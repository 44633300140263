import React from 'react'
import { Carousel } from 'react-responsive-carousel'

import "react-responsive-carousel/lib/styles/carousel.min.css"

interface ICarouselSlider {
  carouselCards: React.ReactChild[]
  autoplay?: boolean
  showArrows?: boolean
  showIndicators?: boolean
  showStatus?: boolean
  showThumbs?: boolean
  swipable?: boolean
  center?: {
    centerMode: boolean
    centerSlidePercentage?: number
  }
  onChange?: ((index: number, item: React.ReactNode) => void)
  selectedItem?: number 
}

const CarouselSlider: React.FC<ICarouselSlider> = ({ 
  carouselCards, 
  autoplay = false, 
  showArrows = false, 
  showIndicators = false, 
  showStatus = false,
  showThumbs = false,
  swipable,
  center,
  onChange,
  selectedItem,
 }) => {

  return (
    <Carousel
      autoPlay={autoplay} 
      centerMode={center?.centerMode} 
      centerSlidePercentage={center?.centerSlidePercentage} 
      showArrows={showArrows}
      showIndicators={showIndicators}
      showStatus={showStatus}
      showThumbs={showThumbs}
      onChange={onChange}
      selectedItem={selectedItem}
      swipeable={swipable}
    >
      {carouselCards.map((card) => {
        return card
      })}
    </Carousel>
  )
}

export default CarouselSlider
