import React from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { Button } from '../../Buttons'
import { CalendarIcon, ArrowRight } from '../../Icons'
import { globalStyles } from '../../../styles'
import { parseMarkdown } from '../../../utils'

export interface ITryClassKidForFreeBlock {
  description: string
  bookDemoButton: {
    url: string
    text: string
  }
  joinButton: {
    text: string
  }
}

const TryClassKidForFreeBlock: React.FC<ITryClassKidForFreeBlock> = ({ description, bookDemoButton, joinButton }) => {
  return (
    <section className={styles.tryClassKidForFreeBlock}>
      <img src="static/images/polygon-transparent2.png" className={styles.polygonRight} />
      <img src="static/images/polygon-copy.png" className={styles.polygonLeft} />
      <img src="static/images/dots.png" className={styles.dots} />
      <h1 className={clsx(globalStyles.heading1, styles.heading)}>Try ClassKid<span id={styles.dot}>.</span> for free!</h1>
      <p className={clsx(globalStyles.body1, styles.description)} dangerouslySetInnerHTML={{ __html: parseMarkdown(description) }} />
      <div className={styles.buttonContainer}>
        <Button 
          className={clsx(styles.button, styles.bookDemoButton)} 
          buttonTextClassName={styles.buttonText}
          text={bookDemoButton.text} 
          url={bookDemoButton.url} 
          startAdornment={<CalendarIcon color="white" className={styles.calendarIcon} />} 
          outline
        />
        <Button 
          className={clsx(styles.button, 'vrlps-trigger')} 
          text={joinButton.text} 
          url="#"
          endAdornment={<ArrowRight color="white" className={styles.arrowIcon} />} 
        />
      </div>
    </section>
  )
}

export default TryClassKidForFreeBlock