import React from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { MenuIcon, CloseIcon } from '../Icons'
import { Button } from '../Buttons'
import { globalStyles } from '../../styles'

interface INavBar {
  navigationLinks: {
    url: string
    title: string
  }[]
  joinButton: {
    text: string
  }
  signInButton: {
    url: string
    text: string
  }
  termsAndConditionsUrl: string
  privacyPolicyUrl: string
}

const NavBar: React.FC<INavBar> = ({ navigationLinks, termsAndConditionsUrl, privacyPolicyUrl, joinButton, signInButton }) => {
  const [menuOpen, setMenuOpen] = React.useState(false)

  const openMenu = () => {
    const menu = document.getElementById('sideMenu')
    setMenuOpen(true)
    if (menu) {menu.style.display = 'block' }
  }

  const closeMenu = () => {
    const menu = document.getElementById('sideMenu')
    if (menu) menu.style.display = 'none'
    setMenuOpen(false)
  }
  
  return (
    <>
      <nav className={clsx(styles.navbar, menuOpen && styles.navbarMenuOpen)}>
        <div>
          <a href="#">
            <img className={styles.logo} src="static/images/class-kid-logo.svg" alt="ClassKid"/>
          </a>
        </div>
        <div className={styles.menuIcon}>
          <MenuIcon color="#160d4d" onClick={openMenu} />
        </div>
        <ul className={styles.linkList}>
          {navigationLinks.map((link, index) => {
            return (
              <li key={`desktop-nav-link-${index}`}>
                <a className={clsx(styles.navbarLink, styles.desktop)} href={link.url}>{link.title}</a>
              </li>
            )
          })}
          <li>
            <div className={styles.divider} />
          </li>
          <li>
            <a className={clsx(styles.navbarLink, styles.desktop)} href={signInButton.url}>{signInButton.text}</a>
          </li>
          <Button url="#" className={clsx(styles.desktop, "vrlps-trigger")} text={joinButton.text} />
        </ul>
      </nav>
      <div id="sideMenu" className={styles.sideMenu}>
        <div className={styles.sideMenuNavbar}>
          <div>
            <a href="#">
              <img className={styles.logo} src="static/images/class-kid-logo.svg" alt="ClassKid"/>
            </a>
          </div>
          <div className={styles.menuIcon}>
            <CloseIcon color="#160d4d" onClick={closeMenu} />
          </div>
        </div>
        <div className={styles.sideMenuContent}>
          <div className={styles.mobileDivider} />
          <div className={styles.mobileLinks}>
            {navigationLinks.map((link, index) => {
              return (
                <li key={`mobile-nav-link-${index}`}>
                  <a className={styles.navbarLink} href={link.url} onClick={closeMenu}>
                    {link.title}
                  </a>
                </li>
              )
            })}
            <li>
              <a className={styles.navbarLink} href={signInButton.url} onClick={closeMenu} >{signInButton.text}</a>
            </li>
          </div>
          <Button url="#" className={clsx(styles.mobileJoinButton, "vrlps-trigger")} text={joinButton.text} />
          <div className={styles.sideMenuFooterLinksContainer}>
            <a href={termsAndConditionsUrl} className={globalStyles.body1}>Terms & conditions</a>
            <a href={privacyPolicyUrl} className={globalStyles.body1}>Privacy policy</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default NavBar