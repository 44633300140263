import React, { ReactNode } from 'react'
import clsx from 'clsx'

import { styles } from './styles'

interface IButton {
  text: string
  url: string
  onClick?: () => void
  className?: string
  buttonTextClassName?: string
  startAdornment?: JSX.Element | ReactNode 
  endAdornment?: JSX.Element | ReactNode 
  outline?: boolean
  download?: boolean
}

interface IImageButton {
  src: string
  width?: number
  height?: number
  url: string
  onClick?: () => void
  className?: string
}

export const Button: React.FC<IButton> = ({ 
  text, 
  url,
  onClick, 
  className, 
  buttonTextClassName = '',
  startAdornment = null, 
  endAdornment = null,
  outline = false,
  download,
 }) => {
  return (
    <a 
      href={url} 
      onClick={onClick} 
      className={clsx(styles.button, outline && styles.buttonOutline, className)}
      download={download} 
    >
      {startAdornment}
      <span className={buttonTextClassName}>{text}</span>
      {endAdornment}
    </a>
  )
}

export const ImageButton: React.FC<IImageButton> = ({ 
  src,
  height,
  width,
  url,
  onClick, 
  className, 
 }) => {
  return (
    <a href={url} onClick={onClick} className={className}>
      <img src={src} height={height} width={width} />
    </a>
  )
}
