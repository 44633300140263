// extracted by mini-css-extract-plugin
export var personalizedDemoBLock = "styles-module--personalizedDemoBLock--2BYta";
export var textOuterContainer = "styles-module--textOuterContainer--1GCpA";
export var textInnerContainer = "styles-module--textInnerContainer--2zkp4";
export var heading = "styles-module--heading--2OMWs";
export var description = "styles-module--description--37ay4";
export var buttonContainer = "styles-module--buttonContainer--1UiCW";
export var bookDemoButton = "styles-module--bookDemoButton--3-ke4";
export var joinButton = "styles-module--joinButton--22wbz";
export var icon = "styles-module--icon--1zzv_";
export var email = "styles-module--email--11BuQ";
export var videoOuterContainer = "styles-module--videoOuterContainer--2EPBf";
export var videoContainer = "styles-module--videoContainer--HfHzK";
export var video = "styles-module--video--eEXu6";
export var mobileHeading = "styles-module--mobileHeading--3bRfA";
export var sendMailContainer = "styles-module--sendMailContainer--XB1sj";