import React from 'react'
import clsx from 'clsx'

import { styles } from './styles' 
import { Button } from '../../Buttons'
import { CalendarIcon, ArrowRight } from '../../Icons'
import { globalStyles } from '../../../styles'
import { parseMarkdown } from '../../../utils'

export interface IHeaderBlock {
  heading: {
    uncoloredText: string
    coloredText: string
  }
  description: string
  subheading: string
  id: string
  bookDemoButton: {
    url: string
    text: string
  }
  joinButton: {
    text: string
  }
}

const HeaderBlock: React.FC<IHeaderBlock> = ({ heading, description, subheading, id, bookDemoButton, joinButton }) => {
  const parsedDescription = parseMarkdown(description)

  return (
    <section className={styles.headerBlockContainer}>
      <div>
        <div className={styles.headerBlockContent}>
          <h1 className={clsx(styles.headerBlockTitle, globalStyles.heading1)}>
            {heading.uncoloredText}<br/> 
            <span id={styles.allInOne}>{heading.coloredText}</span>
          </h1>
          <div className={styles.headerBlockVideoContainerMobile}>
            <img src="static/images/dots.png" className={styles.dots} />
            <iframe 
              src={`//fast.wistia.net/embed/iframe/${id}`}
              allowTransparency
              frameBorder={0}
              scrolling="no" 
              className={styles.headerBlockVideo}
              allowFullScreen 
            />
          </div>
          <p dangerouslySetInnerHTML={{ __html: parsedDescription }} className={clsx(styles.headerBlockSubtitle, globalStyles.body1)} />
          <div className={styles.headerBlockButtonsContainer}>
            <Button
              url={bookDemoButton.url}
              className={styles.headerBlockButton}
              text={bookDemoButton.text}
              onClick={() => 1} 
              startAdornment={<CalendarIcon color='#d63dd9' className={styles.calendarIcon} />} 
              outline
            />
            <Button 
              url="#" 
              className={clsx('vrlps-trigger', styles.headerBlockButton)} 
              text={joinButton.text} 
              endAdornment={<ArrowRight color='white' className={styles.arrowIcon} />} 
            />
          </div>
          <p className={clsx(styles.noWorry, globalStyles.body2)}>
            {subheading}
          </p>
        </div>
      </div>
      <div className={styles.headerBlockVideoContainer}>
        <img src="static/images/polygon.png" className={styles.polygon} />
        <img src="static/images/dots.png" className={styles.dots} />
        <iframe 
          src={`//fast.wistia.net/embed/iframe/${id}`}
          allowTransparency
          frameBorder={0}
          scrolling="no" 
          className={styles.headerBlockVideo}
          allowFullScreen 
        />
      </div>
    </section>
  )
}

export default HeaderBlock