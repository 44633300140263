import React from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { ImageButton } from '../../Buttons'
import { globalStyles } from '../../../styles'
import { parseMarkdown } from '../../../utils'

export interface IBecomeAPartOfSomethingBiggerBlock {
  heading: {
    topUncoloredText: string
    coloredText: string
    bottomUncoloredText: string 
  }
  description: string
  buttonImage: string
  buttonUrl: string
  image: string
}

const BecomeAPartOfSomethingBiggerBlock: React.FC<IBecomeAPartOfSomethingBiggerBlock> = ({ heading, description, buttonImage, buttonUrl, image }) => {
  
  return (
    <section className={styles.becomeAPartOfSomethingBiggerBlock}>
      <div className={styles.textContainer}>
        <h2 className={clsx(globalStyles.heading2, styles.heading)}>
          {heading.topUncoloredText}<br/><span className={styles.highlighted}>{heading.coloredText}</span><br/> {heading.bottomUncoloredText}
        </h2>
        <p className={clsx(globalStyles.body1, styles.description)} dangerouslySetInnerHTML={{__html: parseMarkdown(description) }} />
        <ImageButton src={buttonImage} url={buttonUrl}/>
      </div>
      <div className={styles.imageOuterContainer}>
          <h2 className={clsx(globalStyles.heading2, styles.mobileHeading)}>Become a Part of <br/><span className={styles.highlighted}>Something Bigger</span><br/> than Yourself</h2>
          <div className={styles.imageInnerContainer}>        
            <img className={styles.image} src={image} />
            <img src="static/images/dots.png" className={styles.dots} />
          </div>
      </div>
    </section>
  )
}

export default BecomeAPartOfSomethingBiggerBlock