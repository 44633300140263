import React from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { Button } from '../../Buttons'
import { CalendarIcon, ArrowRight } from '../../Icons'
import { globalStyles } from '../../../styles'
import { parseMarkdown } from '../../../utils'

export interface IMarketYourClassesBlock {
  bookDemoButton: {
    url: string
    text: string
  }
  joinButton: {
    text: string
  }
  marketYourClassesBlockElements: {
    heading: string
    description: string
    frontImage: string
    backImage: string
  }[]
}

interface IMarketYourClassesBlockElement {
  heading: string
  description: string
  frontImage: string
  backImage: string
  containerStyles?: string
  textContainerStyles?: string
  imageContainerStyles?: string
  frontImageStyles?: string
  bookDemoButton: {
    url: string
    text: string
  }
  joinButton: {
    text: string
  }
}

const MarketYourClassesBlockElement: React.FC<IMarketYourClassesBlockElement> = ({ 
  heading, 
  description, 
  frontImage,
  backImage, 
  containerStyles, 
  textContainerStyles, 
  imageContainerStyles,
  frontImageStyles, 
  bookDemoButton, 
  joinButton,
}) => {
  const parsedDescription = parseMarkdown(description)

  return (
    <div className={clsx(styles.container, containerStyles)}>
      <div className={clsx(styles.textContainer, textContainerStyles)}>
        <p className={clsx(globalStyles.heading2, styles.heading)}>{heading}</p>
        <p dangerouslySetInnerHTML={{ __html: parsedDescription }} className={clsx(globalStyles.body1, styles.description)} />
        <div className={styles.buttonsContainer}>
          <Button 
            url={bookDemoButton.url}
            className={styles.button}
            text={bookDemoButton.text} 
            onClick={() => 1} 
            startAdornment={<CalendarIcon color='#d63dd9' className={styles.calendarIcon} />} 
            outline
          />
          <Button
            url="#"
            className={clsx(styles.button, 'vrlps-trigger')}
            text={joinButton.text}
            onClick={() => 1} 
            endAdornment={<ArrowRight color='white' className={styles.arrowIcon} />} 
          />
        </div>
      </div>
      <div className={clsx(styles.imageContainer, imageContainerStyles)}>
        <img src={backImage} className={styles.backgroundImage} />
        <img src={frontImage} className={clsx(styles.frontImage, frontImageStyles)} />
      </div>
      <p className={clsx(globalStyles.heading2, styles.headingMobile)}>{heading}</p>
    </div>
  )
}

const MarketYourClassesBlock: React.FC<IMarketYourClassesBlock> = ({ marketYourClassesBlockElements, joinButton, bookDemoButton }) => {

  return (
    <section className={styles.marketYourClassesBlockContainer}>
      {marketYourClassesBlockElements.map((element, index) => {
        return (
          <MarketYourClassesBlockElement 
            bookDemoButton={bookDemoButton}
            joinButton={joinButton}
            heading={element.heading} 
            description={element.description} 
            frontImage={element.frontImage}
            backImage={element.backImage}
            containerStyles={index%2 && styles.manageClassesContainer} 
            textContainerStyles={index%2 && styles.textContainerStyles}
            imageContainerStyles={index%2 && styles.imageContainerStyles}
            frontImageStyles={index%2 && styles.frontImageStyles}
          />
        )
      })}
    </section>
  )

}

export default MarketYourClassesBlock