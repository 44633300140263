import React from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { Button } from '../../Buttons'
import { CarouselSlider } from '../../CarouselSlider'
import { CalendarIcon, ArrowRight } from '../../Icons'
import { globalStyles } from '../../../styles'
import { parseMarkdown } from '../../../utils'

export interface IGrowWithClassKidBlock {
  heading: string
  features: {
    title: string
    body: string
    image: string
  }[]
  joinButton: {
    text: string
  }
  bookDemoButton: {
    url: string
    text: string
  }
}

const GrowWithClassKidBlock: React.FC<IGrowWithClassKidBlock> = ({ heading, features, joinButton, bookDemoButton }) => {

  const featureCards = features.map((feature, index) => (
    <div className={styles.feature} key={`gwc-feature-${index}`}>
      <img src={feature.image} />
      <p className={clsx(globalStyles.heading3, styles.featureHeading)}>{feature.title}</p>
      <p className={globalStyles.body1} dangerouslySetInnerHTML={{ __html: parseMarkdown(feature.body) }} />
    </div>
  ))

  return (
    <section className={styles.growWithClassKidBlock}>
      <h2 className={clsx(globalStyles.heading2, styles.heading)}>{heading}</h2>
      <ul className={styles.featuresContainer}>
        {featureCards}
      </ul>
      <div className={styles.featureSlider}>
        <CarouselSlider carouselCards={featureCards} center={{ centerMode: true, centerSlidePercentage: 70 }} />
      </div>
      <div className={styles.buttonsContainer}>
        <Button 
          className={styles.bookDemoButton}
          url={bookDemoButton.url} 
          text={bookDemoButton.text} 
          onClick={() => 0} 
          startAdornment={<CalendarIcon color='#d63dd9' className={styles.calendarIcon} />}  
          outline 
        />
        <Button 
          className={clsx(styles.joinButton, 'vrlps-trigger')}
          url="#" 
          text={joinButton.text} 
          onClick={() => 0} 
          endAdornment={<ArrowRight color='white' className={styles.arrowIcon} />}  
        />
      </div>
    </section>
  )
}

export default GrowWithClassKidBlock