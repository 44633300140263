import React from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { Button } from '../../Buttons'
import { DownloadIcon } from '../../Icons'
import { globalStyles } from '../../../styles'
import { parseMarkdown } from '../../../utils'

export interface IGrabACopyBlock {
  heading: string
  subheading: string
  image: string
  downloadButtonUrl: string
  downloadButtonText: string
}

const GrabACopyBlock: React.FC<IGrabACopyBlock> = ({ heading, subheading, image, downloadButtonUrl, downloadButtonText }) => {
  const parsedSubheading = parseMarkdown(subheading)
  return (
    <section className={styles.grabACopyBlockContainer} id="classes-formula">
      <div className={styles.grabACopyBlockImageContainer}>
        <img className={styles.grabACopyBlockImage} src={image} alt="ClassKid"/>
      </div>
      <div className={styles.grabACopyBlockTextOuterContainer}>
        <div className={styles.grabACopyBlockTextInnerContainer}>
          <h2 className={clsx(globalStyles.heading2, styles.text)}>{heading}</h2>
          <p dangerouslySetInnerHTML={ {__html: parsedSubheading }} className={clsx(globalStyles.body1, styles.text)} />
          <div className={styles.buttonContainer}>
            <Button 
              className={styles.button}
              url={downloadButtonUrl} 
              text={downloadButtonText} 
              onClick={() => null} 
              endAdornment={
                <DownloadIcon color='white' className={styles.downloadIcon} />
              } 
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default GrabACopyBlock