import React from 'react'

import { 
  HeaderBlock, 
  IHeaderBlock, 
  GrabACopyBlock, 
  IGrabACopyBlock, 
  WithoutClassKidBlock, 
  IWithoutClassKidBlock,
  MarketYourClassesBlock,
  IMarketYourClassesBlock,
  AttractStudentsBlock,
  IAttractStudentsBlock,
  ConnectWithCommunityBlock,
  IConnectWithCommunityBlock,
  FeaturesBlock,
  IFeaturesBlock,
  GrowWithClassKidBlock,
  IGrowWithClassKidBlock,
  FAQBlock,
  IFAQBlock,
  PersonalizedDemoBlock,
  IPersonalizedDemoBlock,
  BecomeAPartOfSomethingBiggerBlock,
  IBecomeAPartOfSomethingBiggerBlock,
  TryClassKidForFreeBlock,
  ITryClassKidForFreeBlock,
  FooterBlock,
  IFooterBlock
} from '../PageBlocks'

interface IIndexPageTemplate {
  headerBlock: IHeaderBlock
  grabACopyBlock: IGrabACopyBlock
  withoutClassKidBlock: IWithoutClassKidBlock
  marketYourClassesBlock: IMarketYourClassesBlock
  attractStudentsBlock: IAttractStudentsBlock
  connectWithCommunityBlock: IConnectWithCommunityBlock
  featuresBlock: IFeaturesBlock
  growWithClassKidBlock: IGrowWithClassKidBlock
  faqBlock: IFAQBlock
  personalizedDemoBlock: IPersonalizedDemoBlock
  becomeAPartOfSomethingBiggerBlock: IBecomeAPartOfSomethingBiggerBlock
  tryClassKidForFreeBlock: ITryClassKidForFreeBlock
  footerBlock: IFooterBlock
  bookDemoButton: {
    url: string 
    text: string
  }
  joinButton: {
    text: string
  }
}

const IndexPageTemplate: React.FC<IIndexPageTemplate> = ({ 
  headerBlock, 
  grabACopyBlock, 
  withoutClassKidBlock, 
  marketYourClassesBlock,
  attractStudentsBlock,
  connectWithCommunityBlock,
  featuresBlock,
  growWithClassKidBlock,
  faqBlock,
  personalizedDemoBlock,
  becomeAPartOfSomethingBiggerBlock,
  tryClassKidForFreeBlock,
  footerBlock,
  bookDemoButton,
  joinButton,
}) => {
  return (
    <div>
      <HeaderBlock
        heading={headerBlock.heading}
        description={headerBlock.description} 
        subheading={headerBlock.subheading} 
        id={headerBlock.id}
        joinButton={joinButton} 
        bookDemoButton={bookDemoButton} 
      />
      <GrabACopyBlock 
        heading={grabACopyBlock.heading} 
        subheading={grabACopyBlock.subheading} 
        image={grabACopyBlock.image}
        downloadButtonText={grabACopyBlock.downloadButtonText}
        downloadButtonUrl={grabACopyBlock.downloadButtonUrl} 
      />
      <WithoutClassKidBlock 
        heading1={withoutClassKidBlock.heading1} 
        heading2={withoutClassKidBlock.heading2} 
        description={withoutClassKidBlock.description} 
        testimonials={withoutClassKidBlock.testimonials}
      />
      <MarketYourClassesBlock 
        marketYourClassesBlockElements={marketYourClassesBlock.marketYourClassesBlockElements}
        joinButton={joinButton} 
        bookDemoButton={bookDemoButton} 
      />
      <AttractStudentsBlock image={attractStudentsBlock.image} sliderContent={attractStudentsBlock.sliderContent} />
      <ConnectWithCommunityBlock 
        heading={connectWithCommunityBlock.heading} 
        body={connectWithCommunityBlock.body} 
        id={connectWithCommunityBlock.id}
      />
      <FeaturesBlock heading={featuresBlock.heading} features={featuresBlock.features} />
      <GrowWithClassKidBlock 
        heading={growWithClassKidBlock.heading} 
        features={growWithClassKidBlock.features} 
        joinButton={joinButton} 
        bookDemoButton={bookDemoButton} 
      />
      <FAQBlock heading={faqBlock.heading} questions={faqBlock.questions} />
      <PersonalizedDemoBlock 
        heading={personalizedDemoBlock.heading} 
        description={personalizedDemoBlock.description}
        mailToText={personalizedDemoBlock.mailToText}
        email={personalizedDemoBlock.email}
        id={personalizedDemoBlock.id}
        bookDemoButton={bookDemoButton}
        joinButton={joinButton}
      />
      <BecomeAPartOfSomethingBiggerBlock 
        heading={becomeAPartOfSomethingBiggerBlock.heading}
        description={becomeAPartOfSomethingBiggerBlock.description}
        buttonImage={becomeAPartOfSomethingBiggerBlock.buttonImage}
        buttonUrl={becomeAPartOfSomethingBiggerBlock.buttonUrl}
        image={becomeAPartOfSomethingBiggerBlock.image} 
      />
      <TryClassKidForFreeBlock 
        description={tryClassKidForFreeBlock.description} 
        joinButton={joinButton} 
        bookDemoButton={bookDemoButton} 
      />
      <FooterBlock 
        description={footerBlock.description}
        platform={footerBlock.platform}
        learn={footerBlock.learn}
        help={footerBlock.help}
        instagramUrl={footerBlock.instagramUrl}
        facebookUrl={footerBlock.facebookUrl}
        termsAndConditionsUrl={footerBlock.termsAndConditionsUrl}
        privacyPolicyUrl={footerBlock.privacyPolicyUrl}
      />
    </div>
  )
}

export default IndexPageTemplate
