import React from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { TestimonialCard, ITestimonialCard } from '../../TestimonialCard'
import { TestimonialSlider } from '../../TestimonialSlider'
import { globalStyles } from '../../../styles'
import { parseMarkdown } from '../../../utils'

export interface IWithoutClassKidBlock {
  heading1: string
  heading2: string
  description: string
  testimonials: ITestimonialCard[]
}

const WithoutClassKidBlock: React.FC<IWithoutClassKidBlock> = ({ heading1, heading2, description, testimonials }) => {
  const parsedDescription = parseMarkdown(description)

  const hiddenRef = React.useRef<HTMLHeadingElement>(null)

  const isBrowser = () => typeof window !== "undefined"

  const isMobile = isBrowser() && window.innerWidth <= 600
    
  React.useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('scroll', scrollHandler);
      return () => window.removeEventListener('scroll', scrollHandler)
    }
  }, [])
    
  const scrollHandler = () => {
    const logoBlock = document.getElementById('classKid-block')
    const logosAnimationBlock = document.getElementById('logos-block')
    const logos = document.getElementsByClassName('animation-logo')

    if (logoBlock && logosAnimationBlock && logos && isBrowser()) {
      if (window.pageYOffset + window.innerHeight >= (hiddenRef.current?.offsetTop || 0)) {
        logoBlock.style.opacity = '1'
        logosAnimationBlock.style.opacity = '0'
        Array.prototype.map.call(logos, (logo) => {
          logo.style.transform = 'rotate(0) translate3d(0px, 0px, 0px)'
        })
      }
      else {
        logoBlock.style.opacity = '0'
        logosAnimationBlock.style.opacity = '1'
        if (!isMobile) {
          document.getElementById('img1')!.style.transform = `rotate(20deg) translate3d(-90px, -50px, 0px)`
          document.getElementById('img2')!.style.transform = `rotate(-10deg) translate3d(-30px, -60px, 0px)`
          document.getElementById('img3')!.style.transform = `rotate(-20deg) translate3d(30px, -70px, 0px)`
          document.getElementById('img4')!.style.transform = `rotate(40deg) translate3d(-20px, -90px, 0px)`
          document.getElementById('img5')!.style.transform = `rotate(40deg) translate3d(-90px, 50px, 0px)`
          document.getElementById('img6')!.style.transform = `rotate(-10deg) translate3d(-40px, -50px, 0px)`
          document.getElementById('img7')!.style.transform = `rotate(0deg) translate3d(30px, -50px, 0px)`
          document.getElementById('img8')!.style.transform = `rotate(-30deg) translate3d(90px, 30px, 0px)`
          document.getElementById('img9')!.style.transform = `rotate(10deg) translate3d(-90px, 30px, 0px)`
          document.getElementById('img10')!.style.transform = `rotate(-20deg) translate3d(20px, -50px, 0px)`
          document.getElementById('img11')!.style.transform = `rotate(10deg) translate3d(40px, -40px, 0px)`
          document.getElementById('img12')!.style.transform = `rotate(-40deg) translate3d(70px, 60px, 0px)`
          document.getElementById('img13')!.style.transform = `rotate(-20deg) translate3d(-70px, 40px, 0px)`
          document.getElementById('img14')!.style.transform = `rotate(10deg) translate3d(-40px, 10px, 0px)`
          document.getElementById('img15')!.style.transform = `rotate(-30deg) translate3d(-40px, 10px, 0px)`
          document.getElementById('img16')!.style.transform = `rotate(30deg) translate3d(40px, -10px, 0px)`
        }
        else {
          document.getElementById('img1')!.style.transform = `rotate(20deg) translate3d(-50px, -30px, 0px)`
          document.getElementById('img2')!.style.transform = `rotate(-10deg) translate3d(-10px, -40px, 0px)`
          document.getElementById('img3')!.style.transform = `rotate(-20deg) translate3d(10px, -50px, 0px)`
          document.getElementById('img4')!.style.transform = `rotate(40deg) translate3d(20px, -50px, 0px)`
          document.getElementById('img5')!.style.transform = `rotate(40deg) translate3d(-50px, 30px, 0px)`
          document.getElementById('img6')!.style.transform = `rotate(-10deg) translate3d(-20px, -30px, 0px)`
          document.getElementById('img7')!.style.transform = `rotate(0deg) translate3d(10px, -30px, 0px)`
          document.getElementById('img8')!.style.transform = `rotate(-30deg) translate3d(55px, 10px, 0px)`
          document.getElementById('img9')!.style.transform = `rotate(10deg) translate3d(-50px, 10px, 0px)`
          document.getElementById('img10')!.style.transform = `rotate(-20deg) translate3d(20px, -30px, 0px)`
          document.getElementById('img11')!.style.transform = `rotate(10deg) translate3d(20px, -20px, 0px)`
          document.getElementById('img12')!.style.transform = `rotate(-40deg) translate3d(50px, 40px, 0px)`
          document.getElementById('img13')!.style.transform = `rotate(-20deg) translate3d(-50px, 20px, 0px)`
          document.getElementById('img14')!.style.transform = `rotate(10deg) translate3d(-20px, 10px, 0px)`
          document.getElementById('img15')!.style.transform = `rotate(-30deg) translate3d(-20px, 10px, 0px)`
          document.getElementById('img16')!.style.transform = `rotate(30deg) translate3d(20px, -10px, 0px)`
        }
      }
    }
  }

  return (
    <section className={styles.withoutClassKidBlockContainer} id="how-it-works">
      <div className={styles.withoutClassKidBlockInnerContainer}>
        <div className={styles.withoutClassKidTextContainer}>
          <h2 className={clsx(globalStyles.heading2, styles.heading1)}>
            {heading1}
          </h2>
          <div className={styles.logosAnimationContainer}>
            <div className={styles.logosAnimationClassKid} id="classKid-block">
              <img src="static/images/class-kid-logo.svg" />
            </div>
            <div className={styles.logosAnimationBlock} id="logos-block">
                <img src="static/images/puzz-puzz-1.png" id="img1" className={`${styles.img1} animation-logo`} />
                <img src="static/images/puzz-puzz-2.png" id="img2" className={`${styles.img2} animation-logo`} />
                <img src="static/images/puzz-puzz-3.png" id="img3" className={`${styles.img3} animation-logo`} />
                <img src="static/images/puzz-puzz-4.png" id="img4" className={`${styles.img4} animation-logo`} />
                <img src="static/images/puzz-puzz-5.png" id="img5" className={`${styles.img5} animation-logo`} />
                <img src="static/images/puzz-puzz-6.png" id="img6" className={`${styles.img6} animation-logo`} />
                <img src="static/images/puzz-puzz-7.png" id="img7" className={`${styles.img7} animation-logo`} />
                <img src="static/images/puzz-puzz-8.png" id="img8" className={`${styles.img8} animation-logo`} />
                <img src="static/images/puzz-puzz-9.png" id="img9" className={`${styles.img9} animation-logo`} />
                <img src="static/images/puzz-puzz-10.png" id="img10" className={`${styles.img10} animation-logo`} />
                <img src="static/images/puzz-puzz-11.png" id="img11" className={`${styles.img11} animation-logo`} />
                <img src="static/images/puzz-puzz-12.png" id="img12" className={`${styles.img12} animation-logo`} />
                <img src="static/images/puzz-puzz-13.png" id="img13" className={`${styles.img13} animation-logo`} />
                <img src="static/images/puzz-puzz-14.png" id="img14" className={`${styles.img14} animation-logo`} />
                <img src="static/images/puzz-puzz-15.png" id="img15" className={`${styles.img15} animation-logo`} />
                <img src="static/images/puzz-puzz-16.png" id="img16" className={`${styles.img16} animation-logo`} />
            </div>
          </div>
          <h2 className={clsx(globalStyles.heading2, styles.heading2)} ref={isMobile ? undefined : hiddenRef}>
            {heading2}
          </h2>
          <p 
            dangerouslySetInnerHTML={{ __html:parsedDescription }} 
            className={clsx(globalStyles.body1, styles.description)} 
            ref={isMobile ? hiddenRef : undefined} 
          />
        </div>
      </div>
      <div className={styles.testimonialCardList}>
        {testimonials.map((testimonial, index) => {
          return (
            <li key={`testimonial-${index}`}>
              <TestimonialCard 
                name={testimonial.name} 
                position={testimonial.position} 
                avatar={testimonial.avatar} 
                text={testimonial.text} 
              />
            </li>
          )
        })}
      </div>
      <div className={styles.testimonialMobileSlider}>
        <TestimonialSlider testimonials={testimonials} />
      </div>
    </section>
  )
}

export default WithoutClassKidBlock