// extracted by mini-css-extract-plugin
export var footerBlock = "styles-module--footerBlock--2XuuW";
export var upperHalfContainer = "styles-module--upperHalfContainer--1xZ97";
export var logoContainer = "styles-module--logoContainer--304xh";
export var logo = "styles-module--logo--1BfSz";
export var footerNav = "styles-module--footerNav--2ZPb1";
export var linkHeading = "styles-module--linkHeading--iVxy9";
export var footerLink = "styles-module--footerLink--3Bvqv";
export var help = "styles-module--help--1LIVn";
export var contactLabel = "styles-module--contactLabel--2pPhV";
export var contactMail = "styles-module--contactMail--2y3W9";
export var contactContainer = "styles-module--contactContainer--2i-1f";
export var lowerHalfContainer = "styles-module--lowerHalfContainer--1k9fQ";
export var socialIconsContainer = "styles-module--socialIconsContainer--3aGaT";
export var termsAndPrivacyContainer = "styles-module--termsAndPrivacyContainer--1s_dK";
export var termsAndPrivacy = "styles-module--termsAndPrivacy--2RXz0";