import React from 'react'
import clsx from 'clsx'

import { styles } from './styles'
import { globalStyles } from '../../../styles'
import { parseMarkdown } from '../../../utils'

export interface IConnectWithCommunityBlock {
  heading: string
  body: string
  id: string
}

const ConnectWithCommunityBlock: React.FC<IConnectWithCommunityBlock> = ({ heading, body, id }) => {

  return (
    <section className={styles.connectWithCommunityBlock}>
      <div className={styles.connectWithCommunityBlockInnerContainer}>
        <img src="static/images/polygon-transparent.png" className={styles.polygon} />
        <img src="static/images/dots.png" className={styles.dots} />
        <div className={styles.textContainer}>
          <p className={clsx(globalStyles.heading2, styles.heading)}>{heading}</p>
          <p className={clsx(globalStyles.body1, styles.body)} dangerouslySetInnerHTML={{ __html: parseMarkdown(body) }} />
        </div>
        <div className={styles.videoOuterContainer}>
          <p className={clsx(globalStyles.heading2, styles.heading, styles.tabletHeading)}>{heading}</p>
          <div className={styles.videoInnerContainer}>
            <iframe 
              src={`//fast.wistia.net/embed/iframe/${id}`}
              allowTransparency
              frameBorder={0}
              scrolling="no" 
              className={styles.video}
              allowFullScreen 
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ConnectWithCommunityBlock