// extracted by mini-css-extract-plugin
export var headerBlockContainer = "styles-module--headerBlockContainer--1nO2U";
export var headerBlockVideoContainerMobile = "styles-module--headerBlockVideoContainerMobile--1NI4j";
export var headerBlockContent = "styles-module--headerBlockContent--IS5nP";
export var headerBlockVideoContainer = "styles-module--headerBlockVideoContainer--w2pXb";
export var polygon = "styles-module--polygon--3DojQ";
export var dots = "styles-module--dots--3eGrB";
export var headerBlockVideo = "styles-module--headerBlockVideo--2AwRZ";
export var headerBlockTitle = "styles-module--headerBlockTitle--3SGD3";
export var headerBlockSubtitle = "styles-module--headerBlockSubtitle--3L3Jl";
export var allInOne = "styles-module--allInOne--2EaWq";
export var headerBlockButtonsContainer = "styles-module--headerBlockButtonsContainer--ZFrGZ";
export var headerBlockButton = "styles-module--headerBlockButton--17-PI";
export var calendarIcon = "styles-module--calendarIcon--3qytR";
export var arrowIcon = "styles-module--arrowIcon--1FKBs";
export var noWorry = "styles-module--noWorry--1mGxC";
export var joinButton = "styles-module--joinButton--3vzWK";