// extracted by mini-css-extract-plugin
export var growWithClassKidBlock = "styles-module--growWithClassKidBlock--3NPIh";
export var heading = "styles-module--heading--2U88B";
export var featuresContainer = "styles-module--featuresContainer--2b_7I";
export var feature = "styles-module--feature--1IXaz";
export var featureSlider = "styles-module--featureSlider--1Iuvb";
export var featureHeading = "styles-module--featureHeading--XlOWD";
export var buttonsContainer = "styles-module--buttonsContainer--35J1o";
export var bookDemoButton = "styles-module--bookDemoButton--L89hh";
export var joinButton = "styles-module--joinButton--12p0p";
export var calendarIcon = "styles-module--calendarIcon--3ED8F";
export var arrowIcon = "styles-module--arrowIcon--3EtR9";