// extracted by mini-css-extract-plugin
export var becomeAPartOfSomethingBiggerBlock = "styles-module--becomeAPartOfSomethingBiggerBlock--23djI";
export var textContainer = "styles-module--textContainer--3YH08";
export var heading = "styles-module--heading--2Sj3d";
export var highlighted = "styles-module--highlighted--3XNB2";
export var description = "styles-module--description--37Jiu";
export var imageOuterContainer = "styles-module--imageOuterContainer--Qtv7O";
export var imageInnerContainer = "styles-module--imageInnerContainer--1Dbj9";
export var image = "styles-module--image--3hrbe";
export var dots = "styles-module--dots--2wzV3";
export var mobileHeading = "styles-module--mobileHeading--3w7Tw";